.product-filter__popover {
    @apply absolute w-[165px] left-[-10px] right-0 top-[55px] bg-white text-left py-3 px-4 shadow-lg;
    color: var(--theme-color-primary2);
    &::before {
        content: "";
        @apply absolute top-[-18px] w-full h-[20px] left-0 right-0;
    }
    &::after {
        content: "";
        @apply absolute top-[-9px] left-[25px] w-0 h-0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #fff;
    }
    a {
        @apply block text-xs mb-1 text-primary2 transition-all duration-100 ease-linear;
        font-family: var(--theme-font-body);
        &:hover {
            @apply underline text-primary3;
        }
    }
}