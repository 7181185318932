// Entry Point for eMR Styles
@tailwind utilities;
@import "../variables";
@import "../modules/medpassLoginBtn";
@import "../modules/pdfBulkDownload";
@import "../modules/overwrite-platform";
@import "../modules/emrProductNav";
@import "../modules/emrAreaNav";
@import "../modules/medpassLoginBtn";
@import "../modules/productInfo";
@import "../modules/diseasesMenu";
@import "../modules/productListingHeader";
@import "../modules/productItemModal";
@import "../modules/productFilterPopover";
@import "../modules/emrLoading";
@import "../modules/akamaiDcfLookUp";
@import "../modules/emrError";
@import "../modules/eventsCalendar";