.dcfInputGroup {
    label {
        p {
            display: block;
        }
    }

    div.red-text,
    label.red-text {
        p {
            @apply text-red-600 font-bold;
        }
    }
}

.emr-authForm {
    label {
        p {
            display: block;
        }
    }

    #dcf-search-results {
        border: 1px solid #000;
    }

    .dcf-facility-resultName {
        color: #7a00e6;
    }
}

.dcf-search-results__noresults {
    border: 1px solid red;
}
