.productTitleTable {
	thead {
		th {
			border-right: 1px solid var(--theme-color-tertiary6);
			height: 445px;
			&:not(:first-child) {
				padding-top: 48px;
				writing-mode: vertical-rl;
				text-align: left;
				width: 4%;
			}
			&:last-child {
				width: 1%;
			}
			&.expand {
				width: 8%;
			}
		}
	}
	.vertical {
		.eigo {
			-webkit-text-combine: horizontal;
			-ms-text-combine-horizontal: all;
			text-combine-upright: all;
		}
	}
}

.productListTable {
	tbody {
		td {
			border: 1px solid var(--theme-color-tertiary6);
			height: 60px;
			&:not(:first-child) {
				width: 4%;
			}
			&.expand {
				width: 8%;
			}
		}
	}
}

.blue-ball {
	position: relative;
	width: 9px;
	height: 9px;
	border: 1px solid;
	display: block;
	border-radius: 10px;
	background: var(--theme-color-primary2);;
	text-align: center;
	&::before {
		content: "";
		position: absolute;
		width: 15px;
		height: 1px;
		bottom: -7px;
		left: -4px;
		background: var(--theme-color-primary2);;
	}
}