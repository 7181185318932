.tabProductInfo__button {
    border-radius: 4px 4px 0 0;
}

.assetItem__buttonWrapper {
    .assetItem__button {
        @apply border-none px-0 py-0 rounded-none;
    }
}

.modal__parent {
    .productGalleryModal__wrapper {
        @apply px-3 pb-3 pt-4 rounded;
    }
}
