.emr {
	&-error {
		@apply fixed top-1/4 left-1/2 -translate-x-1/2 shadow-lg py-4 px-6 z-50 bg-white opacity-0 transition-all;
		animation: opac .3s;
		color: var(--theme-color-secondary5);
	}
	&-notif {
		color: var(--theme-color-white);
		&-info {
			background-color: var(--theme-color-secondary1);
		}
		&-warn {
			background-color: var(--theme-color-secondary7);
		}
		&-success {
			background-color: var(--theme-color-secondary4);
		}
		&-error {
			background-color: var(--theme-color-secondary5);
		}

	}
}
#orderForm {
	select:invalid {
		color: gray;
	}
}

@keyframes opac{from{opacity:0} to{opacity:1}}