.emr {
	&-loading {
		position: fixed;
		top: calc(50% - 35px);
		left: 0;
		right: 0;
		margin: 0 auto;
		border: 5px solid var(--theme-color-white);
		border-top: 5px solid var(--theme-color-primary2);
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 2s linear infinite;
		z-index: 10001;
	}
	&-overlay {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba($color: #000000, $alpha: 0.6);
		z-index: 10000;
	}
}
