.product-item__modal {
    @media screen and (max-height: 800px) {
        .modal {
            @apply top-[58%];
        }
        .content {
            @apply mt-3 ml-5 mr-10;
        }
        .button__close {
            @apply pr-8 pt-4;
        }
        .divider {
            @apply mt-3;
        }
        .group-button {
            @apply py-4 px-10;
        }
    }
}
