.titledBox {

	.titledBox__header {
		background-color: var(--theme-forms-header-color);
	}

	.titledBox__content {
		@media (min-width: $width-medium-tablet-min) {
			padding-top: 1rem;
		}
	}

	.titledBox__title {
		background-color: var(--theme-button-bg-color);
		border-color: var(--theme-forms-border-color);
		color: var(--theme-forms-header-color);

		@media (min-width: $width-medium-tablet-min) {
			max-height: 7.375rem;
			min-height: 7.375rem;
		}
	}

	.button {
		border-radius: 0.1875rem;
		max-width: 14.625rem;
	}
}

.authForm--V2 {
	
	border-radius: 0;
	border-width: 1px;
	padding: 0!important;
	
	.authForm__title {
		background-color: var(--theme-button-bg-color);
		border: none;
		color: var(--theme-forms-header-color);
		font-size: 1rem;
		max-height: 7.375rem;
		margin: 0;
		min-height: 7.375rem;
		padding: 2.5625rem 0;
		text-align: center;		
	}

	.authForm__header p {
		text-align: center;
	}

	.capture_backgroundColor {
		padding: 1rem;

		@media (min-width: $width-medium-tablet-min) {
			padding: 2rem 4rem;
		}
	}
}