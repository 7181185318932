.emrProductNav__productsWrapper {
	> ul {
		@apply grid;
	}
}

.emrProductNav__medicineCategoriesWrapper {
	> ul {
		@apply grid lg:grid-cols-none lg:flex;
	}
}

.caret{
    @apply border-solid border-transparent;
}

.caret__right {
	border-left-color: var(--theme-color-primary2);
}